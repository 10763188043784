<template>
  <!-- 宠物类别详情页面 -->
  <moe-page title="宠物类别详情">
    <moe-describe-list title="宠物类别信息">
      <moe-describe-item label="宠物类别"
        >{{ category.varieties }}
      </moe-describe-item>
      <moe-describe-item label="宠物类型"
        >{{ $moe_format.getPetType(category.pesType) }}
      </moe-describe-item>
      <moe-describe-item label="宠物体型">
        {{ $moe_format.getPetContour(category.shape) }}
      </moe-describe-item>
      <moe-describe-item label="宠物体重范围">
        {{ category.weightMin + '-' + category.weightMax }}
      </moe-describe-item>
      <moe-describe-item label="宠物主图">
        <moe-image
          style="width: 100px; height: 100px"
          :src="category.img"
          width="100px"
          height="100px"
        />
      </moe-describe-item>
    </moe-describe-list>
  </moe-page>
</template>

<script>
export default {
  name: 'PetCategoryDetail',
  data() {
    return {
      category: {}, //宠物类别信息
    };
  },
  created() {
    //宠物获取类别详情
    this.getCategoryDetail();
  },
  methods: {
    /**
     * 获取宠物类别详情
     **/
    getCategoryDetail() {
      const id = this.$route.query.id;
      // 调用宠物类别详情API
      this.$moe_api.PET_API.petCategoryDetail({ id }).then((data) => {
        if (data.code == 200) {
          let c = data.result;

          // 回显宠物分类
          this.category = {
            id: c.id, //类别ID
            varieties: c.varieties, //类别名称
            pesType: c.pesType, //宠物类型
            shape: c.shape, //宠物体型
            weightMin: c.weightMin, //最小重量
            weightMax: c.weightMax, //最大重量
            img: c.img, //宠物主图
          };
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },
  },
};
</script>